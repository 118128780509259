<template>
  <v-row v-if="rewardDefinition.type === 'POINT_REWARD'">
    <v-icon class="mr-1">mdi-medal</v-icon>
    <span>{{ rewardDefinition.amount }} {{ $t('pages.leaderboard_definitions.points_for') }} <span class="font-italic"> {{ rewardDefinition.identifier }}</span></span>

  </v-row>
  <div v-else>TODO</div>
</template>

<script>
export default {
  name: 'RewardDefinitionInline',
  props: {
    rewardDefinition: { type: Object, required: true },
  },
};
</script>

<style scoped>

</style>
