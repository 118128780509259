<template>
  <v-card >
    <v-card-title class="justify-center">#{{ pointRewardDefinition.id }} - {{ pointRewardDefinition.identifier }}</v-card-title>
    <v-card-text class="text-center text-xl-h4">
      {{ pointRewardDefinition.amount }} <v-icon large>mdi-medal</v-icon>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'PointRewardDefinitionCard',
  props: {
    pointRewardDefinition: { type: Object, required: true },
  },
};
</script>

<style scoped>

</style>
