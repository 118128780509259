import FlashUtils from '@/utils/flash.utils';

const STATES = {
  UPDATING: 'updating',
  ERROR: 'error',
  IDLE: 'idle',
};

const UpdateMixin = {
  data: () => ({
    updateMixin: {
      state: STATES.IDLE,
      STATES,
      config: {
        update: 'update',
        updateCallback: null,
      },
    },
  }),
  methods: {
    update() {
      this.updateMixin.state = this.updateMixin.STATES.UPDATING;
      this[this.updateMixin.config.update]()
        .then(({ data }) => {
          this.updateMixin.state = this.updateMixin.STATES.IDLE;
          this.$emit('updated', data);
          FlashUtils.sendSuccess(this.$t('common.messages.success', { action: this.$t('common.words.modification') }));
        })
        .catch(() => {
          this.updateMixin.state = this.updateMixin.STATES.ERROR;
          FlashUtils.sendError(this.$t('common.messages.failure', { action: this.$t('common.words.modification') }));
        })
        .finally(() => {
          if (this.updateMixin.config.updateCallback != null) {
            this[this.updateMixin.config.updateCallback]();
          }
        });
    },
  },
  computed: {
    isIdle() {
      return this.updateMixin.state === STATES.IDLE;
    },
    isLoading() {
      return this.updateMixin.state === STATES.UPDATING;
    },
    isError() {
      return this.updateMixin.state === STATES.ERROR;
    },
  },
};

export default UpdateMixin;
