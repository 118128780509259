<template>
  <v-col>
    <v-pagination
      :value="pagination.page"
      :length="pagination.pageCount"
      @input="updatePage"
      :total-visible="10"
    />
  </v-col>
<!--  <v-col>-->
<!--    <v-row align="center">-->
<!--      <div class="pr-4">Nombre d'élements :</div>-->
<!--      <v-select-->
<!--        :value="pagination.size"-->
<!--        :items="sizes"-->
<!--        @change="updateSize"-->
<!--      />-->
<!--    </v-row>-->
<!--  </v-col>-->
</template>

<script>
export default {
  name: 'paginator',
  props: {
    pagination: { type: Object, required: true },
  },
  computed: {
    sizes() {
      const sizes = [
        { text: '10', value: 10 },
        { text: '20', value: 20 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
        { text: '200', value: 200 },
        { text: '500', value: 500 },
      ];

      if (sizes.filter(({ value }) => value === this.pagination.size).length === 0) {
        sizes.push({ text: `${this.pagination.size}`, value: this.pagination.size });
      }

      sizes.sort((a, b) => a.value - b.value);
      return sizes;
    },
  },
  methods: {
    updatePage(page) {
      this.update(page, this.pagination.size);
    },
    updateSize(size) {
      this.update(this.pagination.page, size);
    },
    update(page, size) {
      this.$emit('update', { page, size });
    },
  },
};
</script>
