<template>
  <v-container class="flex-column">
    <v-row>
      {{ $t('pages.leaderboard_definitions.selected_choices') }} :
    </v-row>
    <v-row v-if="selection.length > 0">
      <v-col v-for="rewardDefinition in selection" :key="rewardDefinition.id" @click="toggle(rewardDefinition)">
        <PointRewardDefinitionCard class="selected" :point-reward-definition="rewardDefinition"></PointRewardDefinitionCard>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-card-text>
        {{ $t('pages.leaderboard_definitions.no_selected_rewards') }}
      </v-card-text>
    </v-row>
    <v-row>
      {{ $t('pages.leaderboard_definitions.available_choices') }} :
    </v-row>
    <v-row class="flex" v-if="rewardDefinitions.length > 0 && selection.length < limit">
      <v-col v-for="rewardDefinition in rewardDefinitions" :key="rewardDefinition.id" @click="toggle(rewardDefinition)">
        <PointRewardDefinitionCard class="justify-center" :point-reward-definition="rewardDefinition"></PointRewardDefinitionCard>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-card-text>
        {{ $t('pages.leaderboard_definitions.all_selected') }}
      </v-card-text>
    </v-row>
  </v-container>
</template>

<script>
import PointRewardDefinitionCard from '@/components/rewardDefinition/PointRewardDefinitionCard.vue';
import FetchMixin from '@/mixins/fech.mixin';
import RewardDefinitionService from '@/services/gamificator/rewarddefinition.service';

export default {
  name: 'RewardSelection',
  components: { PointRewardDefinitionCard },
  mixins: [FetchMixin],
  props: {
    limit: { type: Number, default: 1000 },
  },
  data: () => ({
    rewardDefinitions: [],
    selection: [],
  }),
  methods: {
    fetchReward() {
      const { page, size } = this.fetchMixin.pagination || { page: 1, size: 20 };
      return RewardDefinitionService.fetchRewardDefinitions({ page, size });
    },
    toggle(rewardDefinition) {
      if (!this.selection.find((el) => el.id === rewardDefinition.id)) {
        this.rewardDefinitions = this.rewardDefinitions.filter((e) => e.id !== rewardDefinition.id);
        this.selection.push(rewardDefinition);
      } else {
        this.selection = this.selection.filter((e) => e.id !== rewardDefinition.id);
        this.rewardDefinitions.push(rewardDefinition);
      }
      this.$emit('selected', this.idFiltered);
    },
  },
  computed: {
    idFiltered() {
      return this.selection.map((e) => e.id);
    },
  },
  created() {
    this.fetchMixin.config = {
      dataField: 'rewardDefinitions',
      fetch: 'fetchReward',
    };
    this.fetch();
  },
};
</script>

<style scoped>
.selected{
  background-color: #2196F3;
}

</style>
