import FlashUtils from '@/utils/flash.utils';

const STATES = {
  LOADING: 'loading', ERROR: 'error', IDLE: 'idle',
};

const DeleteMixin = {
  data: () => ({
    deleteMixin: {
      state: STATES.IDLE,
      STATES,
      config: {
        delete: 'delete',
        callback: 'callback',
      },
    },
  }),
  computed: {
    isIdle() {
      return this.fetchMixin.state === STATES.IDLE;
    },
    isLoading() {
      return this.fetchMixin.state === STATES.LOADING;
    },
    isError() {
      return this.fetchMixin.state === STATES.ERROR;
    },
  },
  methods: {
    remove(id) {
      this.deleteMixin.state = this.deleteMixin.STATES.LOADING;

      this[this.deleteMixin.config.delete](id).then(() => {
        this.deleteMixin.state = this.deleteMixin.STATES.IDLE;
        this.$emit('deleted', id);
        FlashUtils.sendSuccess(this.$t('common.messages.success', { action: this.$t('common.words.deletion') }));
        if (this[this.deleteMixin.config.callback] != null) {
          this[this.deleteMixin.config.callback](id);
        }
      }).catch(() => {
        this.deleteMixin.state = this.deleteMixin.STATES.ERROR;
        FlashUtils.sendError(this.$t('common.messages.failure', { action: this.$t('common.words.deletion') }));
      });
    },
  },
};

export default DeleteMixin;
