import FlashUtils from '@/utils/flash.utils';

const STATES = {
  CREATING: 'creating', ERROR: 'error', IDLE: 'idle',
};

const CreateMixin = {
  data: () => ({
    createMixin: {
      state: STATES.IDLE,
      STATES,
      config: {
        create: 'create',
        callback: 'callback',
      },
    },
  }),
  methods: {
    create() {
      this.createMixin.state = this.createMixin.STATES.CREATING;
      this[this.createMixin.config.create]().then(({ data }) => {
        this.createMixin.state = this.createMixin.STATES.IDLE;
        FlashUtils.sendSuccess(this.$t('common.messages.success', { action: this.$t('common.words.creation') }));
        if (this[this.createMixin.config.callback] != null) {
          this[this.createMixin.config.callback](data);
        }
      }).catch(() => {
        this.createMixin.state = this.createMixin.STATES.ERROR;
        FlashUtils.sendError(this.$t('common.messages.failure', { action: this.$t('common.words.creation') }));
      });
    },
    createWithParam(params) {
      this.createMixin.state = this.createMixin.STATES.CREATING;
      this[this.createMixin.config.create](params).then(({ data }) => {
        this.createMixin.state = this.createMixin.STATES.IDLE;
        FlashUtils.sendSuccess(this.$t('common.messages.success', { action: this.$t('common.words.creation') }));
        if (this[this.createMixin.config.callback] != null) {
          this[this.createMixin.config.callback](data);
        }
      }).catch(() => {
        this.createMixin.state = this.createMixin.STATES.ERROR;
        FlashUtils.sendError(this.$t('common.messages.failure', { action: this.$t('common.words.creation') }));
      });
    },
  },
  computed: {
    isIdle() {
      return this.createMixin.state === STATES.IDLE;
    },
    isLoading() {
      return this.createMixin.state === STATES.CREATING;
    },
    isError() {
      return this.createMixin.state === STATES.ERROR;
    },
  },
};

export default CreateMixin;
